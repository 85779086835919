<template>
  <App
    title="Create"
    :showFooterTabBar="true"
    activeName="newCreate"
    left-arrow
  >
    <section class="full-container">
      <Search
        v-model="keyword"
        @search="onSearch"
        placeholder="Phone number"
      >
      </Search>
      <!-- <Icon name="service-o"
              slot="right-icon" /> -->
      <template v-if="haseDate">
        <List
          v-model="list.loading"
          :finished="list.finished"
          @load="onLoadList"
          class="card-list"
        >
          <Card
            v-for="e in cardData"
            :key="e.id"
          >
            <div class="content">
              <div class="header">
                <div>
                  {{e.qbsAccountName}}
                  <img
                    style="width:20px;height:auto;vertical-align: middle;"
                    src="../../assets/images/icon/male.png"
                    v-if="e.gender === '1'"
                  >
                  <img
                    style="width:20px;height:auto;vertical-align: middle;"
                    src="../../assets/images/icon/girl.png"
                    v-if="e.gender === '0'"
                  >
                </div>
                <div class="status">{{e.statusName}}</div>
              </div>
              <div class="customer-info">
                <div class="info">
                  <div style="margin-bottom: 4px;">
                    <Icon
                      name="phone"
                      size="18"
                    />{{e.qbsAccountPhone}}
                  </div>
                  <div>Latest follow-up:{{e.qbsFollowupTime}}</div>
                </div>
                <div class="operation">
                  <!-- <div style="margin-right: 20px;">
                    <Icon name="chat"
                          size="18" />
                  </div> -->
                  <!-- <div>
                    <Icon name="phone"
                          size="18" />
                  </div> -->
                </div>
              </div>
              <div class="customer-info">
                <div class="info">
                  <div>Follow-up details:{{e.qbsFollowupMessage}}</div>
                </div>
              </div>

              <!-- <Collapse v-model="e.activeNames"
                        :border="false">
                <CollapseItem title="跟进信息"
                              name="followupInfo"
                              :border="false">{{e.followupInfo}}</CollapseItem>
              </Collapse> -->
            </div>
            <div
              slot="footer"
              class="footer"
            >
              <div class="consultant">
                <!-- <Icon
                  name="manager"
                  size="14"
                />
                <span>顾问：{{e.ownerUserName}}</span> -->
              </div>
              <Button
                v-if="e.status !== 'OPEN' && e.status !== 'CONVERTED'"
                @click="onFollowUp(e.eosLeadsId)"
                type="primary"
                size="mini"
                class="follow-up"
              >Follow up</Button>
              <Button
                v-if="e.status=== 'OPEN'"
                @click="leadsApply(e.eosLeadsId)"
                type="primary"
                size="mini"
                class="follow-up"
              >认领</Button>
            </div>
          </Card>
        </List>
      </template>
      <template v-else>
        <div class="no-customer">
          <div class="icon">
            <Icon
              name="manager"
              size="54"
            />
          </div>
          <div class="desc">Couldn't find any existed customer.</div>
          <div class="desc">Click to create a new one.</div>
          <Button
            type="primary"
            class="create"
            size="small"
            @click="create"
          >Create</Button>
        </div>
      </template>
    </section>
  </App>
</template>

<script>
import { leadssearch, leadsapply } from '@/api/clue'
import Card from '_c/card/index';
import { Search, Icon, List, Button, Toast } from 'vant';
import mobile from '@/config/regexp.js'
import dayjs from 'dayjs'
import './index.less';

export default {
  name: 'newCreate',
  components: { Search, Icon, List, Card, Button },
  data () {
    return {
      keyword: '',
      list: {
        loading: false,
        finished: true
      },
      cardData: [],
      haseDate: true
    }
  },
  methods: {
    onSearch () {
      if (mobile.mobile.reg.test(this.keyword)) {
        this.getDataList()
      } else {
        Toast(mobile.mobile.msg)
      }
    },
    onFollowUp (id) {
      this.$router.push({
        path: '/cluesFollowUpFrom',
        query: { eosLeadsId: id }
      })
    },
    // 认领
    async leadsApply (id) {
      const res = await leadsapply({ 'leadsId': id })
      if (res.success) {
        Toast('认领成功')
        this.getDataList()
      } else {
        Toast(res.msg)
      }
    },
    // 列表加载
    onLoadList () {
      console.log('loading')
    },
    // 新建
    create () {
      if (mobile.mobile.reg.test(this.keyword)) {
        this.$router.push({
          path: '/newCreateForm',
          query: {
            'tel': this.keyword,
            'reload': true
          }
        })
      } else {
        Toast('请填写正确的手机号')
      }
    },
    getDataList () {
      const params = {
        phone: this.keyword,
        carseries: this.$storage.get('baseData')['intention_car_series'][0]['value']
      }
      Toast.allowMultiple()
      const t = Toast.loading({
        duration: 0,
        message: 'Loading...',
        forbidClick: true,
      })
      leadssearch(params).then(res => {
        if (res.success) {
          if (res.data.length > 0) {
            this.haseDate = true
            res.data.forEach(item => {
              this.$storage.get('baseData')['leads_status'].forEach(el => {
                if (item.status === el.value) {
                  item['statusName'] = el.key
                }
              })
              this.$storage.get('baseData').gende.forEach(g => {
                if (item.qbsHibernateStatus === g.value) {
                  item.qbsHibernateStatus = g.key
                }
              })
              item.qbsFollowupTime = item.qbsFollowupTime ? dayjs(item.qbsFollowupTime).format('YYYY-MM-DD HH:mm:ss') : ''
            })
          } else {
            this.haseDate = false
          }

          this.cardData = res.data
        } else {
          Toast.fail(res.msg)
        }
      }).finally(() => {
        t.clear()
      })
    },
    init () {
      this.keyword = ''
      this.cardData = []
      this.haseDate = true
    }
  },
  mounted () {
    this.init()
  }
}
</script>
